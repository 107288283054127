import type { WatchAssetParameters } from '@wagmi/core'
import { useAccount, useSwitchChain, useWatchAsset } from 'wagmi'

import { useIsBrowser } from './use-is-browser'

type WatchAssetParametersOptions = WatchAssetParameters['options']

interface UseWatchChainAsset
  extends Omit<WatchAssetParametersOptions, 'address'> {
  chainId?: number
  address?: string
}

export const useWatchChainAsset = ({
  chainId,
  address,
  symbol,
  decimals,
  image,
}: UseWatchChainAsset) => {
  const isBrowser = useIsBrowser()
  const { isConnected, chainId: currentChainId } = useAccount()
  const { watchAsset } = useWatchAsset()
  const { switchChain } = useSwitchChain()

  if (!isBrowser || !isConnected) return

  if (!chainId || !address) return

  const watchAssetParameters = {
    type: 'ERC20',
    options: { address, symbol, decimals, image },
  } as const

  if (chainId !== currentChainId)
    return () =>
      switchChain(
        { chainId },
        {
          onSuccess: () => {
            watchAsset(watchAssetParameters)
          },
        }
      )

  return () => watchAsset(watchAssetParameters)
}
