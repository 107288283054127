import { useEffect, useState } from 'react'

export function useIsBrowser() {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(window !== undefined)
  }, [])

  return isBrowser
}
